import type { Auth, User } from "firebase/auth";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";

import type { LoadingHook } from "../util";
import { useLoadingValue } from "../util";

export type AuthStateHook = LoadingHook<User | null, Error>;

type AuthStateOptions = {
  onUserChanged?: (user: User | null) => Promise<void>;
};

/**
 * @see https://github.com/CSFrequency/react-firebase-hooks/blob/master/auth/useAuthState.ts
 */
export default (auth: Auth, options?: AuthStateOptions): AuthStateHook => {
  const { error, loading, setError, setValue, value } = useLoadingValue<
    User | null,
    Error
  >(() => auth.currentUser);

  useEffect(() => {
    const listener = onAuthStateChanged(
      auth,
      async (user) => {
        if (options?.onUserChanged) {
          // onUserChanged function to process custom claims on any other trigger function
          try {
            await options.onUserChanged(user);
          } catch (e) {
            setError(e as Error);
          }
        }
        setValue(user);
      },
      setError,
    );

    return () => {
      listener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return [value, loading, error];
};
