import { Analytics } from "@vercel/analytics/react";

export function VercelAnalytics() {
  return (
    <Analytics
      debug={true}
      beforeSend={(event) => {
        const url = new URL(event.url);

        switch (true) {
          case event.url.includes("/view-network"): {
            const [, , , tab, appSlugOrInstalledPage] = url.pathname.split("/");

            if (
              !!appSlugOrInstalledPage &&
              appSlugOrInstalledPage?.trim() !== ""
            ) {
              url.pathname = `/view-network/:id/${tab}/${appSlugOrInstalledPage}`;
            } else {
              url.pathname = `/view-network/:id/${tab}`;
            }

            return {
              ...event,
              url: url.toString(),
            };
          }
          case event.url.includes("/install/"): {
            const [, , , slug] = url.pathname.split("/");

            url.pathname = `/install/:id/${slug}`;

            return {
              ...event,
              url: url.toString(),
            };
          }
          case event.url.includes("/deploy/"): {
            const [, , id] = url.pathname.split("/");

            if (!id || id?.trim() === "") {
              return event;
            }

            url.pathname = `/deploy/:id`;

            return {
              ...event,
              url: url.toString(),
            };
          }
          case event.url.includes("/nodes/settings"): {
            return {
              ...event,
            };
          }
          case event.url.includes("/nodes/"): {
            const [, , key] = url.pathname.split("/");

            if (!key || key?.trim() === "") {
              return event;
            }

            url.pathname = `/rpc-key/:key`;

            return {
              ...event,
              url: url.toString(),
            };
          }
          default: {
            return event;
          }
        }
      }}
    />
  );
}
