import { FirebaseError, getApps, initializeApp } from "firebase/app";
import {
  type ActionCodeSettings,
  AuthErrorCodes,
  getAuth,
  getMultiFactorResolver as _getMultiFactorResolver,
  GoogleAuthProvider,
  isSignInWithEmailLink as _isSignInWithEmailLink,
  multiFactor,
  type MultiFactorAssertion,
  type MultiFactorError,
  sendSignInLinkToEmail as _sendSignInLinkToEmail,
  signInWithEmailLink as _signInWithEmailLink,
  signInWithPopup,
  signOut as _signOut,
  TotpMultiFactorGenerator,
  type TotpSecret,
  type User,
} from "firebase/auth";
export * from "./hooks";

import { EMAIL_FOR_SIGN_IN } from "../../constants/localStorage";
import { EmailAuthURL } from "../../constants/urls";
import { config } from "./config";

export const app =
  getApps().length === 0
    ? initializeApp(config, { automaticDataCollectionEnabled: false })
    : getApps()[0];

export const auth = getAuth(app);

export function sendSignInLinkToEmail(email: string, redirect?: string) {
  try {
    /**
     * Wrap localStorage in try catch to prevent errors when user has disabled localStorage
     */
    window.localStorage.setItem(EMAIL_FOR_SIGN_IN, email);
  } catch (error) {
    console.error(error);
  }

  const actionCodeSettings = {
    handleCodeInApp: true,
    url: redirect
      ? `${EmailAuthURL}?redirect_uri=${encodeURIComponent(redirect)}`
      : EmailAuthURL,
  } satisfies ActionCodeSettings;

  return _sendSignInLinkToEmail(auth, email, actionCodeSettings);
}

export function signInWithEmailLink(email: string, emailLink?: string) {
  return _signInWithEmailLink(auth, email, emailLink);
}

export function isSignInWithEmailLink(emailLink: string) {
  return _isSignInWithEmailLink(auth, emailLink);
}

export function signInWithGoogle() {
  const provider = new GoogleAuthProvider();

  return signInWithPopup(auth, provider);
}

export function signOut() {
  return _signOut(auth);
}

export async function logOut() {
  await signOut();

  window.location.reload();
}

export async function generateTOTPSecret(user: User) {
  const multiFactorSession = await multiFactor(user).getSession();

  return TotpMultiFactorGenerator.generateSecret(multiFactorSession);
}

export function generateQrCodeUrl(
  totpSecret: TotpSecret,
  accountName: string,
  issuer: string,
) {
  return totpSecret.generateQrCodeUrl(accountName, issuer);
}

export function assertionForEnrollment(
  totpSecret: TotpSecret,
  oneTimePassword: string,
) {
  return TotpMultiFactorGenerator.assertionForEnrollment(
    totpSecret,
    oneTimePassword,
  );
}

export async function enrollUserMFA(
  multiFactorAssertion: MultiFactorAssertion,
  user: User,
  displayName: string,
) {
  return multiFactor(user).enroll(multiFactorAssertion, displayName);
}

export function getMultiFactorResolver(error: MultiFactorError) {
  return _getMultiFactorResolver(auth, error);
}

export function requiresRecentLogin(err: unknown): err is FirebaseError {
  if (
    err instanceof FirebaseError &&
    err.code === AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN
  ) {
    return true;
  }

  return false;
}

export function requiresMultiFactorAuth(err: unknown): err is MultiFactorError {
  if (
    err instanceof FirebaseError &&
    err.code === AuthErrorCodes.MFA_REQUIRED
  ) {
    return true;
  }

  return false;
}

export function handleTotp(err: MultiFactorError, oneTimePassword: string) {
  const resolver = getMultiFactorResolver(err);

  if (resolver.hints[0].factorId === TotpMultiFactorGenerator.FACTOR_ID) {
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
      resolver.hints[0].uid,
      oneTimePassword,
    );

    return resolver.resolveSignIn(multiFactorAssertion);
  }

  throw new Error("Unhandled Factor");
}
