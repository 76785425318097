import type { DataAvailability } from "./dataAvailabilityDetails";
import type { DeploymentType } from "./deploymentTypes";
import type { RollupEnvironment } from "./environmentDetails";
import type { Framework } from "./frameworkDetails";
import {
  anvilLogo,
  arbLogo,
  dockerLogo,
  erigonLogo,
  gethLogo,
  opLogo,
  xfacLogo,
} from "./logos/24";
import type { CostItem } from "./rollupBilling";
import type { SettlementLayer } from "./settlementLayerDetails";
import { SharedCostItems, SharedLineItems } from "./summaryMetadata";
import {
  DATA_AVAILABILITY,
  FRAMEWORKS,
  ROLLUP_ENVIRONMENT,
  SETTLEMENT_LAYERS,
} from "./templates";

export const DeploymentImage = {
  ANVIL: anvilLogo,
  GETH: gethLogo,
  ERIGON: erigonLogo,
  OPTIMISM: opLogo,
  DOCKER: dockerLogo,
  ORBIT: arbLogo,
  UNKNOWN: xfacLogo,
} as const;

export const DeploymentNetwork = {
  SEPOLIA: "Testnet",
  MAINNET: "Mainnet",
} as const;

interface DeploymentMetadataBase {
  name: string;
  /**
   * Parsed with MDX to enrich with icons
   */
  builtOnSummary: string;
  /**
   * Parsed with MDX to enrich with icons
   */
  yourOwnSummary: string;
  /**
   * Additional notice above the Deploy button, for example; telling the user they need to go through the Arbitrum Orbit Expansion Program
   */
  deployMessage?: string;
  /**
   * Network name, Mainnet or Sepolia, @todo refactor to be pulled from environment
   */
  network:
    | (typeof DeploymentNetwork)[keyof typeof DeploymentNetwork]
    | "UNKNOWN";
  /**
   * Image of the stack to display
   */
  image: (typeof DeploymentImage)[keyof typeof DeploymentImage];

  dataAvailability: DataAvailability;
  environment: RollupEnvironment;
  framework: Framework | "UNKNOWN";
  settlementLayer: SettlementLayer | "UNKNOWN";
}

interface DeploymentMetadataConfigurable extends DeploymentMetadataBase {
  isConfigurable: true;
  /**
   * Enables permission-less deployment of the given deployment type
   */
  selfServe: boolean;
  includedLineItems: readonly string[];
  costsLineItems: readonly CostItem[];
  /**
   * Indicates the maturity of a stack
   */
  maturity: DeploymentMaturity;
}

interface DeploymentMetadataNotConfigurable extends DeploymentMetadataBase {
  isConfigurable?: never;
  /**
   * Enables permission-less deployment of the given deployment type
   */
  selfServe?: never;
  includedLineItems?: never;
  costsLineItems?: never;
  /**
   * Indicates the maturity of a stack
   */
  maturity?: never;
}

export const DeploymentMaturity = {
  ALPHA: "ALPHA",
  BETA: "BETA",
  STABLE: "STABLE",
} as const;

export type DeploymentMaturity = keyof typeof DeploymentMaturity;

type DeploymentMetadataMap = {
  [key in DeploymentType]:
    | DeploymentMetadataNotConfigurable
    | DeploymentMetadataConfigurable;
};

export const DeploymentMetadataMap: DeploymentMetadataMap = {
  DEPLOYMENTTYPE_ANVIL: {
    name: "Anvil",
    builtOnSummary: "Built on Anvil",
    yourOwnSummary: "...",
    framework: "UNKNOWN",
    network: "UNKNOWN",
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: "UNKNOWN",
    image: DeploymentImage.ANVIL,
  },
  DEPLOYMENTTYPE_GETH: {
    name: "Geth",
    builtOnSummary: "Built on Geth",
    yourOwnSummary: "...",
    framework: "UNKNOWN",
    network: "UNKNOWN",
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: "UNKNOWN",
    image: DeploymentImage.GETH,
  },
  DEPLOYMENTTYPE_ERIGON: {
    name: "Erigon",
    builtOnSummary: "Built on Erigon",
    yourOwnSummary: "...",
    framework: "UNKNOWN",
    network: "UNKNOWN",
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: "UNKNOWN",
    image: DeploymentImage.ERIGON,
  },
  DEPLOYMENTTYPE_OPTIMISM: {
    name: "OP Stack",
    builtOnSummary: "Built on <Optimism /> with <Ethereum />",
    yourOwnSummary: "...",
    framework: FRAMEWORKS.OPTIMISM,
    network: "UNKNOWN",
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: SETTLEMENT_LAYERS.L1,
    image: DeploymentImage.OPTIMISM,
  },
  DEPLOYMENTTYPE_CUSTOM: {
    name: "Your Own Container",
    builtOnSummary: "Built on your own container",
    yourOwnSummary: "...",
    framework: "UNKNOWN",
    network: "UNKNOWN",
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: "UNKNOWN",
    image: DeploymentImage.DOCKER,
  },
  DEPLOYMENTTYPE_OPTIMISM_MAINNET: {
    name: "OP Stack (Mainnet)",
    builtOnSummary: "Built on <Optimism /> with <Ethereum />",
    yourOwnSummary: "Your own fully-managed **Mainnet L2**",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.MAINNET,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: SETTLEMENT_LAYERS.L1,
    image: DeploymentImage.OPTIMISM,

    isConfigurable: true,
    selfServe: false,
    includedLineItems: SharedLineItems.ENTERPRISE_CONTRACT_MAINNET,
    costsLineItems: SharedCostItems.ENTERPRISE_MAINNET_OPTIMISM,

    maturity: DeploymentMaturity.STABLE,
  },
  DEPLOYMENTTYPE_OPTIMISM_DEVNET: {
    name: "OP Stack (Devnet)",
    builtOnSummary: "Built on <Optimism /> with <Ethereum />",
    yourOwnSummary: "...",
    framework: FRAMEWORKS.OPTIMISM,
    network: "UNKNOWN",
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: SETTLEMENT_LAYERS.L1,
    image: DeploymentImage.OPTIMISM,
  },
  DEPLOYMENTTYPE_OPTIMISM_GOERLI: {
    name: "OP Stack (Goerli)",
    builtOnSummary: "Built on <Optimism /> with <Ethereum />",
    yourOwnSummary: "...",
    framework: FRAMEWORKS.OPTIMISM,
    network: "UNKNOWN",
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: SETTLEMENT_LAYERS.L1,
    image: DeploymentImage.OPTIMISM,
  },
  DEPLOYMENTTYPE_OPTIMISM_MAINNET_L3: {
    name: "OP Stack (Optimism Mainnet)",
    builtOnSummary: "Built on <Optimism /> with <Ethereum />",
    yourOwnSummary: "Your own fully-managed **Mainnet L2**",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.MAINNET,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: SETTLEMENT_LAYERS.L1,
    image: DeploymentImage.OPTIMISM,
  },
  DEPLOYMENTTYPE_OPTIMISM_GOERLI_L3: {
    name: "OP Stack (Optimism Goerli)",
    builtOnSummary: "Built on <Optimism /> with <Ethereum />",
    yourOwnSummary: "Your own **Testnet L2** (Goerli)",
    framework: FRAMEWORKS.OPTIMISM,
    network: "UNKNOWN",
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: SETTLEMENT_LAYERS.L1,
    image: DeploymentImage.OPTIMISM,
  },
  DEPLOYMENTTYPE_OPTIMISM_CHAINMAKER_TEST: {
    name: "OP Stack (Chainmaker Test)",
    builtOnSummary: "Built on <Optimism /> with <Ethereum />",
    yourOwnSummary: "Your own **Testnet L2** (Chainmaker)",
    framework: FRAMEWORKS.OPTIMISM,
    network: "UNKNOWN",
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: SETTLEMENT_LAYERS.L1,
    image: DeploymentImage.OPTIMISM,
  },
  DEPLOYMENTTYPE_OPTIMISM_SEPOLIA: {
    name: "OP Stack (Sepolia)",
    builtOnSummary: "Built on <Optimism /> with <Ethereum />",
    yourOwnSummary: "Your own **Testnet L2** (Sepolia)",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: SETTLEMENT_LAYERS.L1,
    image: DeploymentImage.OPTIMISM,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_TESTNET,
    costsLineItems: SharedCostItems.SELF_SERVE_TESTNET,

    maturity: DeploymentMaturity.STABLE,
  },
  DEPLOYMENTTYPE_OPTIMISM_SEPOLIA_CELESTIA: {
    name: "OP Stack (Sepolia Celestia)",
    builtOnSummary: "Built on <Optimism /> with <Celestia />",
    yourOwnSummary: "Your own **Testnet L2** (Sepolia)",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.CELESTIA,
    settlementLayer: SETTLEMENT_LAYERS.L1,
    image: DeploymentImage.OPTIMISM,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_TESTNET,
    costsLineItems: SharedCostItems.SELF_SERVE_TESTNET,

    maturity: DeploymentMaturity.BETA,
  },
  DEPLOYMENTTYPE_OPTIMISM_MAINNET_CELESTIA: {
    name: "OP Stack (Mainnet Celestia)",
    builtOnSummary: "Built on <Optimism /> with <Celestia />",
    yourOwnSummary: "Your own fully-managed **Mainnet L2**",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.MAINNET,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    dataAvailability: DATA_AVAILABILITY.CELESTIA,
    settlementLayer: SETTLEMENT_LAYERS.L1,
    image: DeploymentImage.OPTIMISM,
    maturity: DeploymentMaturity.BETA,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_MAINNET,
    costsLineItems: SharedCostItems.SELF_SERVE_MAINNET_L3,
  },
  DEPLOYMENTTYPE_OPTIMISM_SEPOLIA_EIGENDA: {
    name: "OP Stack (Sepolia EigenDA)",
    builtOnSummary: "Built on <Optimism /> with <EigenDA />",
    yourOwnSummary: "Your own **Testnet L2** (Sepolia)",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.EIGEN,
    settlementLayer: SETTLEMENT_LAYERS.L1,
    image: DeploymentImage.OPTIMISM,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_TESTNET,
    costsLineItems: SharedCostItems.SELF_SERVE_TESTNET,

    maturity: DeploymentMaturity.ALPHA,
  },
  DEPLOYMENTTYPE_OPTIMISM_MAINNET_EIGENDA: {
    name: "OP Stack (Mainnet EigenDA)",
    builtOnSummary: "Built on <Optimism /> with <EigenDA />",
    yourOwnSummary: "Your own fully-managed **Mainnet L2**",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.MAINNET,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    dataAvailability: DATA_AVAILABILITY.EIGEN,
    settlementLayer: SETTLEMENT_LAYERS.L1,
    image: DeploymentImage.OPTIMISM,

    isConfigurable: true,
    selfServe: false,
    includedLineItems: SharedLineItems.ENTERPRISE_CONTRACT_MAINNET,
    costsLineItems: SharedCostItems.SELF_SERVE_MAINNET_L3,

    maturity: DeploymentMaturity.ALPHA,
  },
  DEPLOYMENTTYPE_OPTIMISM_BASE_SEPOLIA_CELESTIA: {
    name: "OP Stack (Base Sepolia Celestia)",
    builtOnSummary: "Built on <Optimism /> with <Celestia />",
    yourOwnSummary: "Your own **Testnet L3** on <Base /> (Sepolia)",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.CELESTIA,
    settlementLayer: SETTLEMENT_LAYERS.BASE,
    image: DeploymentImage.OPTIMISM,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_TESTNET,
    costsLineItems: SharedCostItems.SELF_SERVE_TESTNET,

    maturity: DeploymentMaturity.BETA,
  },
  DEPLOYMENTTYPE_OPTIMISM_ZORA_SEPOLIA_CELESTIA: {
    name: "OP Stack (Zora Sepolia Celestia)",
    builtOnSummary: "Built on <Optimism /> with <Celestia />",
    yourOwnSummary: "Your own **Testnet L3** on <Zora /> (Sepolia)",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.CELESTIA,
    settlementLayer: SETTLEMENT_LAYERS.ZORA,
    image: DeploymentImage.OPTIMISM,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_TESTNET,
    costsLineItems: SharedCostItems.SELF_SERVE_TESTNET,

    maturity: DeploymentMaturity.BETA,
  },
  DEPLOYMENTTYPE_OPTIMISM_MODE_SEPOLIA_CELESTIA: {
    name: "OP Stack (Mode Sepolia Celestia)",
    builtOnSummary: "Built on <Optimism /> with <Celestia />",
    yourOwnSummary: "Your own **Testnet L3** on <Mode /> (Sepolia)",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.CELESTIA,
    settlementLayer: SETTLEMENT_LAYERS.MODE,
    image: DeploymentImage.OPTIMISM,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_TESTNET,
    costsLineItems: SharedCostItems.SELF_SERVE_TESTNET,

    maturity: DeploymentMaturity.BETA,
  },
  DEPLOYMENTTYPE_OPTIMISM_MODE_SEPOLIA_EIGENDA: {
    name: "OP Stack (Mode Sepolia EigenDA)",
    builtOnSummary: "Built on <Optimism /> with <EigenDA />",
    yourOwnSummary: "Your own **Testnet L3** on <Mode /> (Sepolia)",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    settlementLayer: SETTLEMENT_LAYERS.MODE,
    dataAvailability: DATA_AVAILABILITY.EIGEN,
    image: DeploymentImage.OPTIMISM,

    isConfigurable: true,
    selfServe: true,

    includedLineItems: SharedLineItems.SELF_SERVE_TESTNET,
    costsLineItems: SharedCostItems.SELF_SERVE_TESTNET,

    maturity: DeploymentMaturity.ALPHA,
  },
  DEPLOYMENTTYPE_OPTIMISM_MODE_MAINNET_EIGENDA: {
    name: "OP Stack (Mode Mainnet EigenDA)",
    builtOnSummary: "Built on <Optimism /> with <EigenDA />",
    yourOwnSummary: "Your own **Mainnet L3** on <Mode />",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.MAINNET,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    settlementLayer: SETTLEMENT_LAYERS.MODE,
    dataAvailability: DATA_AVAILABILITY.EIGEN,
    image: DeploymentImage.OPTIMISM,

    isConfigurable: true,
    selfServe: false,

    includedLineItems: SharedLineItems.ENTERPRISE_CONTRACT_MAINNET,
    costsLineItems: SharedCostItems.ENTERPRISE_MAINNET_OPTIMISM,

    maturity: DeploymentMaturity.ALPHA,
  },
  DEPLOYMENTTYPE_OPTIMISM_ZORA_MAINNET_CELESTIA: {
    name: "OP Stack (Zora Mainnet Celestia)",
    builtOnSummary: "Built on <Optimism /> with <Celestia />",
    yourOwnSummary: "Your own fully-managed **Mainnet L3** on <Zora />",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.MAINNET,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    dataAvailability: DATA_AVAILABILITY.CELESTIA,
    settlementLayer: SETTLEMENT_LAYERS.ZORA,
    image: DeploymentImage.OPTIMISM,

    isConfigurable: true,
    selfServe: false,
    includedLineItems: SharedLineItems.ENTERPRISE_CONTRACT_MAINNET,
    costsLineItems: SharedCostItems.ENTERPRISE_MAINNET_OPTIMISM,

    maturity: DeploymentMaturity.BETA,
  },
  DEPLOYMENTTYPE_OPTIMISM_MODE_MAINNET_CELESTIA: {
    name: "OP Stack (Mode Mainnet Celestia)",
    builtOnSummary: "Built on <Optimism /> with <Celestia />",
    yourOwnSummary: "Your own fully-managed **Mainnet L3** on <Mode />",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.MAINNET,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    dataAvailability: DATA_AVAILABILITY.CELESTIA,
    settlementLayer: SETTLEMENT_LAYERS.MODE,
    image: DeploymentImage.OPTIMISM,

    isConfigurable: true,
    selfServe: false,
    includedLineItems: SharedLineItems.ENTERPRISE_CONTRACT_MAINNET,
    costsLineItems: SharedCostItems.ENTERPRISE_MAINNET_OPTIMISM,

    maturity: DeploymentMaturity.BETA,
  },
  DEPLOYMENTTYPE_OPTIMISM_BASE_MAINNET_CELESTIA: {
    name: "OP Stack (Base Mainnet Celestia)",
    builtOnSummary: "Built on <Optimism /> with <Celestia />",
    yourOwnSummary: "Your own fully-managed **Mainnet L3** on <Base />",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.MAINNET,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    dataAvailability: DATA_AVAILABILITY.CELESTIA,
    settlementLayer: SETTLEMENT_LAYERS.BASE,
    image: DeploymentImage.OPTIMISM,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_MAINNET,
    costsLineItems: SharedCostItems.SELF_SERVE_MAINNET_L3,

    maturity: DeploymentMaturity.BETA,
  },
  DEPLOYMENTTYPE_OPTIMISM_BASE_MAINNET_EIGENDA: {
    name: "OP Stack (Base Mainnet EigenDA)",
    builtOnSummary: "Built on <Optimism /> with <EigenDA />",
    yourOwnSummary: "Your own fully-managed **Mainnet L3** on <Base />",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.MAINNET,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    dataAvailability: DATA_AVAILABILITY.EIGEN,
    settlementLayer: SETTLEMENT_LAYERS.BASE,
    image: DeploymentImage.OPTIMISM,

    isConfigurable: true,
    selfServe: false,
    includedLineItems: SharedLineItems.SELF_SERVE_MAINNET,
    costsLineItems: SharedCostItems.SELF_SERVE_MAINNET_L3,

    maturity: DeploymentMaturity.ALPHA,
  },
  DEPLOYMENTTYPE_OPTIMISM_ZORA_MAINNET: {
    name: "OP Stack (Zora Mainnet Celestia)",
    builtOnSummary: "Built on <Optimism /> with <Celestia />",
    yourOwnSummary: "Your own fully-managed **Mainnet L3** on <Zora />",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.MAINNET,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: SETTLEMENT_LAYERS.ZORA,
    image: DeploymentImage.OPTIMISM,
  },
  DEPLOYMENTTYPE_OPTIMISM_BASE_SEPOLIA: {
    name: "OP Stack (Base Sepolia Celestia)",
    builtOnSummary: "Built on <Optimism /> with <Celestia />",
    yourOwnSummary: "Your own **Testnet L3** on <Base /> (Sepolia)",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: SETTLEMENT_LAYERS.BASE,
    image: DeploymentImage.OPTIMISM,
  },
  DEPLOYMENTTYPE_OPTIMISM_BASE_MAINNET: {
    name: "OP Stack (Base Mainnet)",
    builtOnSummary: "Built on <Optimism />",
    yourOwnSummary: "Your own fully-managed **Mainnet L3** on <Base />",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.MAINNET,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: SETTLEMENT_LAYERS.BASE,
    image: DeploymentImage.OPTIMISM,
  },
  DEPLOYMENTTYPE_OPTIMISM_BASE_SEPOLIA_EIGENDA: {
    name: "OP Stack (Base Sepolia EigenDA)",
    builtOnSummary: "Built on <Optimism /> with <EigenDA />",
    yourOwnSummary: "Your own **Testnet L3** on <Base /> (Sepolia)",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.EIGEN,
    settlementLayer: SETTLEMENT_LAYERS.BASE,
    image: DeploymentImage.OPTIMISM,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_TESTNET,
    costsLineItems: SharedCostItems.SELF_SERVE_TESTNET,

    maturity: DeploymentMaturity.ALPHA,
  },
  DEPLOYMENTTYPE_OPTIMISM_ZORA_SEPOLIA: {
    name: "OP Stack (Zora Sepolia)",
    builtOnSummary: "Built on <Optimism /> with <Celestia />",
    yourOwnSummary: "Your own **Testnet L3** on <Zora /> (Sepolia)",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: SETTLEMENT_LAYERS.ZORA,
    image: DeploymentImage.OPTIMISM,
  },
  DEPLOYMENTTYPE_OPTIMISM_MODE_SEPOLIA: {
    name: "OP Stack (Mode Sepolia)",
    builtOnSummary: "Built on <Optimism /> with <Celestia />",
    yourOwnSummary: "Your own **Testnet L3** on <Mode /> (Sepolia)",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: SETTLEMENT_LAYERS.MODE,
    image: DeploymentImage.OPTIMISM,
  },
  DEPLOYMENTTYPE_OPTIMISM_MODE_MAINNET: {
    name: "OP Stack (Mode Mainnet)",
    builtOnSummary: "Built on <Optimism /> with <Celestia />",
    yourOwnSummary: "Your own fully-managed **Mainnet L3** on <Mode />",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.MAINNET,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: SETTLEMENT_LAYERS.MODE,
    image: DeploymentImage.OPTIMISM,
  },
  DEPLOYMENTTYPE_OPTIMISM_SEPOLIA_PLASMA: {
    name: "OP Stack (Sepolia Plasma)",
    builtOnSummary: "Built on <Optimism /> with <Plasma />",
    yourOwnSummary: "Your own **Testnet L2** (Sepolia)",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.PLASMA,
    settlementLayer: SETTLEMENT_LAYERS.L1,
    image: DeploymentImage.OPTIMISM,
  },
  DEPLOYMENTTYPE_OPTIMISM_MAINNET_PLASMA: {
    name: "OP Stack (Mainnet Plasma)",
    builtOnSummary: "Built on <Optimism /> with <Plasma />",
    yourOwnSummary: "Your own fully-managed **Mainnet L2**",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.MAINNET,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    dataAvailability: DATA_AVAILABILITY.PLASMA,
    settlementLayer: SETTLEMENT_LAYERS.L1,
    image: DeploymentImage.OPTIMISM,
  },
  DEPLOYMENTTYPE_OPTIMISM_BASE_SEPOLIA_PLASMA: {
    name: "OP Stack (Base Sepolia Plasma)",
    builtOnSummary: "Built on <Optimism /> with <Plasma />",
    yourOwnSummary: "Your own **Testnet L3** on <Base /> (Sepolia)",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.PLASMA,
    settlementLayer: SETTLEMENT_LAYERS.BASE,
    image: DeploymentImage.OPTIMISM,
  },
  DEPLOYMENTTYPE_OPTIMISM_BASE_MAINNET_PLASMA: {
    name: "OP Stack (Base Mainnet Plasma)",
    builtOnSummary: "Built on <Optimism /> with <Plasma />",
    yourOwnSummary: "Your own fully-managed **Mainnet L3** on <Base />",
    framework: FRAMEWORKS.OPTIMISM,
    network: DeploymentNetwork.MAINNET,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    dataAvailability: DATA_AVAILABILITY.PLASMA,
    settlementLayer: SETTLEMENT_LAYERS.BASE,
    image: DeploymentImage.OPTIMISM,
  },

  DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA: {
    name: "Arbitrum Nitro (Arbitrum Sepolia)",
    builtOnSummary: "Built on <ArbitrumOrbit /> with <Ethereum />",
    yourOwnSummary: "Your own **Testnet L3** (Sepolia)",
    framework: FRAMEWORKS.ARBITRUM_ORBIT,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: SETTLEMENT_LAYERS.L2,
    image: DeploymentImage.ORBIT,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_TESTNET,
    costsLineItems: SharedCostItems.SELF_SERVE_TESTNET,

    maturity: DeploymentMaturity.STABLE,
  },
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_CELESTIA: {
    name: "Arbitrum Nitro (Arbitrum Sepolia Celestia)",
    builtOnSummary: "Built on <ArbitrumOrbit /> with <Celestia />",
    yourOwnSummary: "Your own **Testnet L3** (Sepolia)",
    framework: FRAMEWORKS.ARBITRUM_ORBIT,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.CELESTIA,
    settlementLayer: SETTLEMENT_LAYERS.L2,
    image: DeploymentImage.ORBIT,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_TESTNET,
    costsLineItems: SharedCostItems.SELF_SERVE_TESTNET,

    maturity: DeploymentMaturity.BETA,
  },
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_L2: {
    name: "Arbitrum Nitro (Sepolia)",
    builtOnSummary: "Built on <ArbitrumOrbit /> with <Ethereum />",
    yourOwnSummary: "Your own **Testnet L2** (Sepolia)",
    framework: FRAMEWORKS.ARBITRUM_ORBIT,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: SETTLEMENT_LAYERS.L1,
    image: DeploymentImage.ORBIT,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_TESTNET,
    costsLineItems: SharedCostItems.SELF_SERVE_TESTNET,

    maturity: DeploymentMaturity.STABLE,
  },
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_L2_CELESTIA: {
    name: "Arbitrum Nitro (Sepolia Celestia)",
    builtOnSummary: "Built on <ArbitrumOrbit /> with <Celestia />",
    yourOwnSummary: "Your own **Testnet L2** (Sepolia)",
    framework: FRAMEWORKS.ARBITRUM_ORBIT,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.CELESTIA,
    settlementLayer: SETTLEMENT_LAYERS.L1,
    image: DeploymentImage.ORBIT,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_TESTNET,
    costsLineItems: SharedCostItems.SELF_SERVE_TESTNET,

    maturity: DeploymentMaturity.BETA,
  },
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_ARBITRUM_MAINNET: {
    name: "Arbitrum Nitro (Arbitrum Mainnet)",
    builtOnSummary: "Built on <ArbitrumOrbit /> with <Ethereum />",
    yourOwnSummary: "Your own fully-managed **Mainnet L3**",
    framework: FRAMEWORKS.ARBITRUM_ORBIT,
    network: DeploymentNetwork.MAINNET,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: SETTLEMENT_LAYERS.L2,
    image: DeploymentImage.ORBIT,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_MAINNET,
    costsLineItems: SharedCostItems.SELF_SERVE_MAINNET_L3,

    maturity: DeploymentMaturity.STABLE,
  },
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_MAINNET: {
    name: "Arbitrum Nitro (Mainnet)",
    builtOnSummary: "Built on <ArbitrumOrbit />",
    yourOwnSummary: "Your own fully-managed **Mainnet L2**",
    deployMessage:
      "Deploy an L2 with the [Arbitrum Orbit Expansion Program](https://forum.arbitrum.foundation/t/the-arbitrum-expansion-program-and-developer-guild/20722)",
    framework: FRAMEWORKS.ARBITRUM_ORBIT,
    network: DeploymentNetwork.MAINNET,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: SETTLEMENT_LAYERS.L1,
    image: DeploymentImage.ORBIT,

    isConfigurable: true,
    selfServe: false,
    includedLineItems: SharedLineItems.ENTERPRISE_CONTRACT_MAINNET,
    costsLineItems: SharedCostItems.ENTERPRISE_MAINNET_ARBITRUM_L2,

    maturity: DeploymentMaturity.STABLE,
  },
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_ANYTRUST: {
    name: "Arbitrum Nitro (Arbitrum Sepolia AnyTrust)",
    builtOnSummary: "Built on <ArbitrumOrbit /> with <AnyTrust />",
    yourOwnSummary: "Your own **Testnet L3** (Sepolia)",
    framework: FRAMEWORKS.ARBITRUM_ORBIT,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.ANYTRUST,
    settlementLayer: SETTLEMENT_LAYERS.L2,
    image: DeploymentImage.ORBIT,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_TESTNET,
    costsLineItems: SharedCostItems.SELF_SERVE_TESTNET,

    maturity: DeploymentMaturity.STABLE,
  },
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_L2_ANYTRUST: {
    name: "Arbitrum Nitro (Sepolia AnyTrust)",
    builtOnSummary: "Built on <ArbitrumOrbit /> with <AnyTrust />",
    yourOwnSummary: "Your own **Testnet L2** (Sepolia)",
    framework: FRAMEWORKS.ARBITRUM_ORBIT,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.ANYTRUST,
    settlementLayer: SETTLEMENT_LAYERS.L1,
    image: DeploymentImage.ORBIT,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_TESTNET,
    costsLineItems: SharedCostItems.SELF_SERVE_TESTNET,

    maturity: DeploymentMaturity.STABLE,
  },
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_MAINNET_ANYTRUST: {
    name: "Arbitrum Nitro (Arbitrum AnyTrust)",
    builtOnSummary: "Built on <ArbitrumOrbit /> with <AnyTrust />",
    yourOwnSummary: "Your own fully-managed **Mainnet L2**",
    deployMessage:
      "Deploy an L2 with the [Arbitrum Orbit Expansion Program](https://forum.arbitrum.foundation/t/the-arbitrum-expansion-program-and-developer-guild/20722)",
    framework: FRAMEWORKS.ARBITRUM_ORBIT,
    network: DeploymentNetwork.MAINNET,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    dataAvailability: DATA_AVAILABILITY.ANYTRUST,
    settlementLayer: SETTLEMENT_LAYERS.L1,
    image: DeploymentImage.ORBIT,

    isConfigurable: true,
    selfServe: false,
    includedLineItems: SharedLineItems.ENTERPRISE_CONTRACT_MAINNET,
    costsLineItems: SharedCostItems.ENTERPRISE_MAINNET_ARBITRUM_L2,

    maturity: DeploymentMaturity.STABLE,
  },
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_ARBITRUM_MAINNET_ANYTRUST: {
    name: "Arbitrum Nitro (Arbitrum Mainnet AnyTrust)",
    builtOnSummary: "Built on <ArbitrumOrbit /> with <AnyTrust />",
    yourOwnSummary: "Your own fully-managed **Mainnet L3**",
    framework: FRAMEWORKS.ARBITRUM_ORBIT,
    network: DeploymentNetwork.MAINNET,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    dataAvailability: DATA_AVAILABILITY.ANYTRUST,
    settlementLayer: SETTLEMENT_LAYERS.L2,
    image: DeploymentImage.ORBIT,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_MAINNET,
    costsLineItems: SharedCostItems.SELF_SERVE_MAINNET_L3,

    maturity: DeploymentMaturity.STABLE,
  },
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_BASE_SEPOLIA_ANYTRUST: {
    name: "Arbitrum Nitro (Base Sepolia AnyTrust)",
    builtOnSummary: "Built on <ArbitrumOrbit /> with <AnyTrust />",
    yourOwnSummary: "Your own **Testnet L3** on <Base /> (Sepolia)",
    framework: FRAMEWORKS.ARBITRUM_ORBIT,
    network: DeploymentNetwork.SEPOLIA,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.ANYTRUST,
    settlementLayer: SETTLEMENT_LAYERS.BASE,
    image: DeploymentImage.ORBIT,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_TESTNET,
    costsLineItems: SharedCostItems.SELF_SERVE_TESTNET,

    maturity: DeploymentMaturity.STABLE,
  },
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_BASE_MAINNET_ANYTRUST: {
    name: "Arbitrum Nitro (Base Mainnet AnyTrust)",
    builtOnSummary: "Built on <ArbitrumOrbit /> with <AnyTrust />",
    yourOwnSummary: "Your own fully-managed **Mainnet L3** on <Base />",
    framework: FRAMEWORKS.ARBITRUM_ORBIT,
    network: DeploymentNetwork.MAINNET,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    dataAvailability: DATA_AVAILABILITY.ANYTRUST,
    settlementLayer: SETTLEMENT_LAYERS.BASE,
    image: DeploymentImage.ORBIT,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_MAINNET,
    costsLineItems: SharedCostItems.SELF_SERVE_ARBITRUM_L2,

    maturity: DeploymentMaturity.STABLE,
  },

  DEPLOYMENTTYPE_STARKWARE_MADARA_TESTNET: {
    name: "Starkware Madara (Testnet)",
    builtOnSummary: "Built on <Starkware /> with <Ethereum />",
    yourOwnSummary: "...",
    framework: FRAMEWORKS.STARKWARE,
    network: "UNKNOWN",
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    dataAvailability: DATA_AVAILABILITY.ETHEREUM,
    settlementLayer: "UNKNOWN",
    image: DeploymentImage.UNKNOWN,
  },
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_MAINNET_L2_CELESTIA: {
    name: "Arbitrum Nitro (Arbitrum Mainnet Celestia)",
    builtOnSummary: "Built on <ArbitrumOrbit /> with <Celestia />",
    yourOwnSummary: "Your own fully-managed **Mainnet L2**",
    network: DeploymentNetwork.MAINNET,
    image: DeploymentImage.ORBIT,
    dataAvailability: DATA_AVAILABILITY.CELESTIA,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    framework: FRAMEWORKS.ARBITRUM_ORBIT,
    settlementLayer: SETTLEMENT_LAYERS.L1,

    isConfigurable: true,
    selfServe: false,
    includedLineItems: SharedLineItems.ENTERPRISE_CONTRACT_MAINNET,
    costsLineItems: SharedCostItems.ENTERPRISE_MAINNET_ARBITRUM_L2,

    maturity: DeploymentMaturity.BETA,
  },
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_MAINNET_CELESTIA: {
    name: "Arbitrum Nitro (Arbitrum Mainnet Celestia)",
    builtOnSummary: "Built on <ArbitrumOrbit /> with <Celestia />",
    yourOwnSummary: "Your own fully-managed **Mainnet L3**",
    network: DeploymentNetwork.MAINNET,
    image: DeploymentImage.ORBIT,
    dataAvailability: DATA_AVAILABILITY.CELESTIA,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    framework: FRAMEWORKS.ARBITRUM_ORBIT,
    settlementLayer: SETTLEMENT_LAYERS.L2,

    isConfigurable: true,
    selfServe: false,
    includedLineItems: SharedLineItems.ENTERPRISE_CONTRACT_MAINNET,
    costsLineItems: SharedCostItems.SELF_SERVE_MAINNET_L3,

    maturity: DeploymentMaturity.BETA,
  },

  DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_EIGENDA: {
    name: "Arbitrum Nitro (Arbitrum Sepolia EigenDA)",
    builtOnSummary: "Built on <ArbitrumOrbit /> with <EigenDA />",
    yourOwnSummary: "Your own **Testnet L3** (Sepolia)",
    network: DeploymentNetwork.SEPOLIA,
    image: DeploymentImage.ORBIT,
    dataAvailability: DATA_AVAILABILITY.EIGEN,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    framework: FRAMEWORKS.ARBITRUM_ORBIT,
    settlementLayer: SETTLEMENT_LAYERS.L2,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_TESTNET,
    costsLineItems: SharedCostItems.SELF_SERVE_TESTNET,

    maturity: DeploymentMaturity.ALPHA,
  },
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_L2_EIGENDA: {
    name: "Arbitrum Nitro (Sepolia EigenDA)",
    builtOnSummary: "Built on <ArbitrumOrbit /> with <EigenDA />",
    yourOwnSummary: "Your own **Testnet L2** (Sepolia)",
    network: DeploymentNetwork.SEPOLIA,
    image: DeploymentImage.ORBIT,
    dataAvailability: DATA_AVAILABILITY.EIGEN,
    environment: ROLLUP_ENVIRONMENT.TESTNET,
    framework: FRAMEWORKS.ARBITRUM_ORBIT,
    settlementLayer: SETTLEMENT_LAYERS.L1,

    isConfigurable: true,
    selfServe: true,
    includedLineItems: SharedLineItems.SELF_SERVE_TESTNET,
    costsLineItems: SharedCostItems.SELF_SERVE_TESTNET,

    maturity: DeploymentMaturity.ALPHA,
  },

  DEPLOYMENTTYPE_ARBITRUM_ORBIT_MAINNET_EIGENDA: {
    name: "Arbitrum Nitro (Arbitrum Mainnet EigenDA)",
    builtOnSummary: "Built on <ArbitrumOrbit /> with <EigenDA />",
    yourOwnSummary: "Your own fully-managed **Mainnet L3**",
    network: DeploymentNetwork.MAINNET,
    image: DeploymentImage.ORBIT,
    dataAvailability: DATA_AVAILABILITY.EIGEN,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    framework: FRAMEWORKS.ARBITRUM_ORBIT,
    settlementLayer: SETTLEMENT_LAYERS.L2,

    isConfigurable: true,
    selfServe: false,
    includedLineItems: SharedLineItems.ENTERPRISE_CONTRACT_MAINNET,
    costsLineItems: SharedCostItems.SELF_SERVE_MAINNET_L3,

    maturity: DeploymentMaturity.ALPHA,
  },
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_MAINNET_L2_EIGENDA: {
    name: "Arbitrum Nitro (Mainnet EigenDA)",
    builtOnSummary: "Built on <ArbitrumOrbit /> with <EigenDA />",
    yourOwnSummary: "Your own fully-managed **Mainnet L2**",
    network: DeploymentNetwork.MAINNET,
    image: DeploymentImage.ORBIT,
    dataAvailability: DATA_AVAILABILITY.EIGEN,
    environment: ROLLUP_ENVIRONMENT.MAINNET,
    framework: FRAMEWORKS.ARBITRUM_ORBIT,
    settlementLayer: SETTLEMENT_LAYERS.L1,

    isConfigurable: true,
    selfServe: false,
    includedLineItems: SharedLineItems.ENTERPRISE_CONTRACT_MAINNET,
    costsLineItems: SharedCostItems.ENTERPRISE_MAINNET_ARBITRUM_L2,

    maturity: DeploymentMaturity.ALPHA,
  },
};

/**
 * @TODO move these until helpers/metadata.ts
 */

export function getDeploymentNetwork(type: DeploymentType) {
  return DeploymentMetadataMap?.[type].network;
}

export function getDeploymentName(type: DeploymentType) {
  return DeploymentMetadataMap?.[type].name;
}

export function getDeploymentImage(type: DeploymentType) {
  return DeploymentMetadataMap?.[type].image;
}

export function getDataAvailability(type: DeploymentType) {
  return DeploymentMetadataMap?.[type].dataAvailability;
}

export function getEnvironment(type: DeploymentType) {
  return DeploymentMetadataMap?.[type].environment;
}

export function getFramework(type: DeploymentType) {
  return DeploymentMetadataMap?.[type].framework;
}

export function getSettlementLayer(type: DeploymentType) {
  return DeploymentMetadataMap?.[type].settlementLayer;
}

export function getBuiltOn(type: DeploymentType) {
  return DeploymentMetadataMap?.[type]?.builtOnSummary;
}

export function getYourOwn(type: DeploymentType) {
  return DeploymentMetadataMap?.[type]?.yourOwnSummary;
}

export function getDeployMessage(type: DeploymentType) {
  return DeploymentMetadataMap?.[type]?.deployMessage;
}

export function getMaturity(type: DeploymentType) {
  return DeploymentMetadataMap?.[type]?.maturity;
}

export type SummaryDetails = {
  includedLineItems: readonly string[];
  costsLineItems: readonly CostItem[];
  /**
   * Enables permission-less deployment of the given deployment type
   */
  selfServe: boolean;
};

export function getSummary(type: DeploymentType): SummaryDetails {
  const metadata = DeploymentMetadataMap?.[type];

  if (
    metadata.isConfigurable &&
    typeof metadata.selfServe === "boolean" &&
    metadata.includedLineItems &&
    metadata.costsLineItems
  ) {
    return {
      selfServe: metadata.selfServe,
      includedLineItems: metadata.includedLineItems,
      costsLineItems: metadata.costsLineItems,
    };
  }

  throw new Error(
    `Summary metadata not configured for this deployment type, ${type}`,
  );
}
