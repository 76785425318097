/**
 * Rollup Components
 */

export const ROLLUP_ENVIRONMENT = {
  TESTNET: "TESTNET",
  MAINNET: "MAINNET",
} as const;

export const FRAMEWORKS = {
  OPTIMISM: "OPTIMISM",
  ARBITRUM_ORBIT: "ARBITRUM_ORBIT",
  STARKWARE: "STARKWARE",
} as const;

export const SETTLEMENT_LAYERS = {
  L1: "L1",
  /**
   * @note Only for Arbitrum L3s, not Optimism
   */
  L2: "L2",
  BASE: "BASE",
  ZORA: "ZORA",
  MODE: "MODE",
} as const;

export const DATA_AVAILABILITY = {
  ETHEREUM: "ETHEREUM",
  ANYTRUST: "ANYTRUST",
  CELESTIA: "CELESTIA",
  EIGEN: "EIGEN",
  PLASMA: "PLASMA",
} as const;
