import type { Router } from "@remix-run/router";
import * as Sentry from "@sentry/react";
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  redirect,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import BaseLayout from "./layouts/BaseLayout";
import ErrorBoundaryLayout from "./layouts/ErrorBoundaryLayout";
import RootLayout from "./layouts/RootLayout";
import { WithOrgLayout, WithUserLayout } from "./layouts/UserLayout";
import catchAndReload from "./utils/catchAndReload";
import DeployLayout from "./views/deploy/layout";
import ErrorPage from "./views/error";
import NotFoundPage from "./views/not-found";
import OrganizationLayout from "./views/organization-settings/layout";
import DashboardLayout from "./views/view-network/[id]/layout";
import DashboardSettingsLayout from "./views/view-network/[id]/settings/layout";

/**
 * Sentry
 */
Sentry.init({
  enabled: import.meta.env.PROD,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENV,
  integrations: [
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ["xfac"],
      behaviour: "apply-tag-if-contains-third-party-frames",
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    }),
  ],
  tracesSampleRate: 0.25,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracePropagationTargets: [],
  profilesSampleRate: 0.25,
  ignoreErrors: [
    "TypeError: Load failed",
    "TypeError: network error",
    "ResizeObserver loop limit exceeded",
    "LaunchDarklyFlagFetchError",
    "Non-Error exception captured",
    "Non-Error promise rejection captured",
    "UnhandledRejection",
    "Object captured as promise rejection with keys: code, message",
    "UnhandledRejection: Object captured as promise rejection with keys: code, message",
  ],
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router: Router = sentryCreateBrowserRouter([
  {
    element: <ErrorBoundaryLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <RootLayout />,
        children: [
          {
            path: "/published/view/:slug",
            lazy: () =>
              import("./views/published/view/[slug]/page").catch(
                catchAndReload,
              ),
          },
          {
            path: "/deploy",
            element: <DeployLayout />,
            children: [
              {
                index: true,
                lazy: () => import("./views/deploy/page").catch(catchAndReload),
              },
              {
                path: ":id",
                lazy: () =>
                  import("./views/deploy/[id]/page").catch(catchAndReload),
              },
            ],
          },
          {
            path: "/email-signin",
            loader: () => redirect("/"),
          },
          {
            path: "/login",
            loader: () => redirect("/"),
          },
          {
            path: "/",
            lazy: () => import("./views/login/page").catch(catchAndReload),
          },
          {
            path: "/signup",
            lazy: () => import("./views/signup/page").catch(catchAndReload),
          },
          {
            path: "/email-signin-completion",
            lazy: () =>
              import("./views/email-signin-completion/page").catch(
                catchAndReload,
              ),
          },
          {
            element: <WithUserLayout />,
            children: [
              {
                path: "/email-invite",
                lazy: () =>
                  import("./views/email-invite/page").catch(catchAndReload),
              },
              {
                path: "/create-organization",
                lazy: () =>
                  import("./views/create-organization/page").catch(
                    catchAndReload,
                  ),
              },
              {
                element: <WithOrgLayout />,
                children: [
                  {
                    path: "/install/:id/:slug",
                    children: [
                      {
                        index: true,
                        lazy: () =>
                          import("./views/install/[id]/[slug]/page").catch(
                            catchAndReload,
                          ),
                      },
                    ],
                  },
                  {
                    element: <BaseLayout />,
                    children: [
                      {
                        path: "/deployments",
                        lazy: () =>
                          import("./views/deployments/page").catch(
                            catchAndReload,
                          ),
                      },
                      {
                        path: "/browse-networks",
                        lazy: () =>
                          import("./views/browse-networks/page").catch(
                            catchAndReload,
                          ),
                      },
                      {
                        path: "/nodes",
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import("./views/nodes/page").catch(
                                catchAndReload,
                              ),
                          },
                          {
                            path: "settings",
                            lazy: () =>
                              import("./views/nodes/settings/page").catch(
                                catchAndReload,
                              ),
                          },
                          {
                            path: ":key",
                            lazy: () =>
                              import("./views/nodes/[key]/page").catch(
                                catchAndReload,
                              ),
                          },
                        ],
                      },
                      {
                        path: "/organization-settings",
                        element: <OrganizationLayout />,
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import(
                                "./views/organization-settings/page"
                              ).catch(catchAndReload),
                          },
                        ],
                      },
                      {
                        path: "/account",
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import("./views/account/page").catch(
                                catchAndReload,
                              ),
                          },
                        ],
                      },
                      {
                        element: <DashboardLayout />,
                        path: "/view-network/:id/",
                        children: [
                          { index: true, loader: () => redirect("overview") },
                          { path: "access", loader: () => redirect("/nodes") },
                          {
                            path: "overview",
                            lazy: () =>
                              import(
                                "./views/view-network/[id]/overview/page"
                              ).catch(catchAndReload),
                          },
                          {
                            path: "metrics",
                            lazy: () =>
                              import(
                                "./views/view-network/[id]/metrics/page"
                              ).catch(catchAndReload),
                          },
                          {
                            path: "contracts",
                            lazy: () =>
                              import(
                                "./views/view-network/[id]/contracts/page"
                              ).catch(catchAndReload),
                          },
                          {
                            path: "integrations",
                            loader: () => redirect("../apps"),
                          },
                          {
                            path: "integrations/:slug",
                            loader: ({
                              params,
                            }: {
                              params: { slug: string };
                            }) => redirect(`../apps/${params.slug}`),
                          },
                          {
                            path: "apps",
                            children: [
                              {
                                index: true,
                                lazy: () =>
                                  import(
                                    "./views/view-network/[id]/apps/page"
                                  ).catch(catchAndReload),
                              },
                              {
                                path: "installed",
                                lazy: () =>
                                  import(
                                    "./views/view-network/[id]/apps/installed/page"
                                  ).catch(catchAndReload),
                              },
                              {
                                path: ":slug",
                                lazy: () =>
                                  import(
                                    "./views/view-network/[id]/apps/[slug]/page"
                                  ).catch(catchAndReload),
                              },
                            ],
                          },
                          {
                            path: "settings/",
                            element: <DashboardSettingsLayout />,
                            children: [
                              {
                                path: "domains",
                                loader: () => redirect("../customization"),
                              },
                              {
                                index: true,
                                lazy: () =>
                                  import(
                                    "./views/view-network/[id]/settings/general/page"
                                  ).catch(catchAndReload),
                              },
                              {
                                path: "customization",
                                lazy: () =>
                                  import(
                                    "./views/view-network/[id]/settings/customization/page"
                                  ).catch(catchAndReload),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
]);

export default router;
