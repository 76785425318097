import { LifebuoyIcon } from "@heroicons/react/24/outline";
import { track } from "@vercel/analytics";

import { ScheduleDemoURLNoRedirect } from "../../../constants/urls";
import { Button } from "../../../shared/Button";

export default function DiscordGuidanceButton() {
  return (
    <div className="fixed bottom-5 right-6">
      <div className="flex items-center gap-4 rounded-xl border-0.5 border-grey-40 bg-white p-2 pl-4">
        <div className="flex items-center gap-1 text-grey-80">
          <LifebuoyIcon className="size-5" />

          <span className="text-system-m">Need more guidance?</span>
        </div>

        <Button asChild variant="secondary">
          <a
            onClick={() => {
              track("Click Ask in Discord");
            }}
            target="_blank"
            href={ScheduleDemoURLNoRedirect}
          >
            Request demo
          </a>
        </Button>
      </div>
    </div>
  );
}
