import * as AvatarPrimitive from "@radix-ui/react-avatar";
import clsx from "clsx";

import { cn } from "../utils/twx";

export default function SquareAvatar({
  className,
  fallbackClassName,
  fallback,
  imageUrl,
}: {
  className?: string;
  fallbackClassName?: string;
  fallback: string;
  imageUrl?: string;
}) {
  return (
    <AvatarPrimitive.Root
      className={cn(
        "size-6 shrink-0 select-none overflow-hidden rounded bg-grey-10",
        className,
      )}
    >
      <AvatarPrimitive.Image
        className="size-full rounded-[inherit] object-cover"
        draggable={false}
        src={imageUrl}
        alt=""
      />

      <AvatarPrimitive.Fallback
        delayMs={imageUrl ? 200 : 0}
        className={clsx(
          fallbackClassName,
          "flex size-full items-center justify-center rounded-[inherit] bg-blue-60 text-[13px] font-medium uppercase leading-none text-white",
        )}
      >
        {fallback}
      </AvatarPrimitive.Fallback>
    </AvatarPrimitive.Root>
  );
}
