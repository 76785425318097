export const NonRetryableCause = "X-NonRetryableCause";

export class UserFacingError extends Error {
  props: {
    code: number;
    message: string;
    cause?: typeof NonRetryableCause;
  };

  constructor(
    message: string,
    props: {
      code: number;
      message: string;
      cause?: typeof NonRetryableCause;
    },
  ) {
    super(message);

    this.name = "UserFacingError";

    this.props = props;
  }
}

export function assertUserFacingError(
  value: unknown,
): asserts value is UserFacingError {
  if (
    !(
      value instanceof UserFacingError ||
      toString.call(value) === "[object Error]"
    )
  ) {
    throw new TypeError(
      `Expected an \`UserFacingError\`, got \`${JSON.stringify(
        value,
      )}\` (${typeof value})`,
    );
  }
}
