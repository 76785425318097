import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isEmpty, isNullish } from "remeda";

import { useUserAuth } from "../context/authContext";
import { pb } from "../lib/pb";
import Spinner from "../shared/Spinner";
import SystemLayout from "../shared/SystemLayout";

export function WithOrgLayout() {
  const { organizations } = useUserAuth();

  const { pathname } = useLocation();

  /**
   * Organizations will be empty if the user has not logged in, or if they have logged out
   */
  if (isEmpty(organizations)) {
    return (
      <Navigate to={`${pb.createOrganization()}?redirect_uri=${pathname}`} />
    );
  }

  return <Outlet />;
}

export function WithUserLayout() {
  const { user, userObject } = useUserAuth();

  const { pathname, search } = useLocation();

  /**
   * User object will be null if user has logged out, or if there is an error logging in
   */
  if (isNullish(user)) {
    return (
      <Navigate
        to={`${pb.index()}?redirect_uri=${pathname + search}`}
        replace
      />
    );
  }

  /**
   * By default 'user' and 'userObject' are empty objects `{}` if the user has not logged in, once they have logged in they will be populated
   */
  if (
    isEmpty(user as Record<string, unknown>) ||
    isEmpty(userObject as Record<string, unknown>)
  ) {
    return (
      <SystemLayout>
        <Spinner className="size-6 text-accent" />
      </SystemLayout>
    );
  }

  return <Outlet />;
}
