type DeploymentParams = { slugOrId: string };

type PublishedParams = { id: string };

type NodeParams = { key: string };

const dashboardBase = ({ slugOrId }: DeploymentParams) =>
  `${pb.dashboard()}/${slugOrId}`;

/**
 * @name Path Builder
 */
export const pb = {
  index: () => `/`,
  /**
   * Not a route on its own, don't use directly
   */
  dashboard: () => `/view-network`,
  dashboardOverview: (params: DeploymentParams) =>
    `${dashboardBase(params)}/overview`,
  dashboardMetrics: (params: DeploymentParams) =>
    `${dashboardBase(params)}/metrics`,
  dashboardContracts: (params: DeploymentParams) =>
    `${dashboardBase(params)}/contracts`,
  dashboardMarketplace: (params: DeploymentParams) =>
    `${dashboardBase(params)}/apps`,
  dashboardMarketplaceAppInstalled: (params: DeploymentParams) =>
    `${dashboardBase(params)}/apps/installed`,
  dashboardMarketplaceAppPage: (params: DeploymentParams, slug: string) =>
    `${dashboardBase(params)}/apps/${slug}`,
  dashboardSettings: (params: DeploymentParams) =>
    `${dashboardBase(params)}/settings`,
  dashboardCustomizationSettings: (params: DeploymentParams) =>
    `${dashboardBase(params)}/settings/customization`,
  browseNetworks: () => `/browse-networks`,
  nodes: () => `/nodes`,
  nodesSettings: () => `/nodes/settings`,
  nodesApiKey: (params: NodeParams) => `/nodes/${params.key}`,
  deploy: () => `/deploy`,
  deployPostPage: (params: DeploymentParams) => `/deploy/${params.slugOrId}`,
  deployments: () => `/deployments`,
  createOrganization: () => `/create-organization`,
  organizationSettings: () => "/organization-settings",
  publishedView: (params: PublishedParams) => `/published/view/${params.id}`,
  account: () => `/account`,
} as const;
