import {
  CodeBracketIcon,
  CommandLineIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline";
import { entries, find, pipe } from "remeda";
import { zeroAddress } from "viem";

import type { Network } from "../apis/api-types";
import type { DataAvailability } from "../constants/dataAvailabilityDetails";
import {
  DeploymentMetadataMap,
  getDataAvailability,
  getEnvironment,
  getFramework,
} from "../constants/deploymentMetadata";
import { DeploymentType } from "../constants/deploymentTypes";
import type { RollupEnvironment } from "../constants/environmentDetails";
import type { Framework } from "../constants/frameworkDetails";
import type { SettlementLayer } from "../constants/settlementLayerDetails";
import { FRAMEWORKS, ROLLUP_ENVIRONMENT } from "../constants/templates";
import { DATA_AVAILABILITY } from "../constants/templates";

export function isCustomGasTokensSupported(type: DeploymentType) {
  const metadata = DeploymentMetadataMap?.[type];

  if (!metadata) {
    throw new Error(
      `Metadata not configured for this deployment type, ${type}`,
    );
  }

  if (
    metadata.dataAvailability === DATA_AVAILABILITY.ANYTRUST ||
    metadata.dataAvailability === DATA_AVAILABILITY.CELESTIA ||
    metadata.dataAvailability === DATA_AVAILABILITY.EIGEN
  ) {
    return true;
  }

  return false;
}

export function isSequencerRevenueChartSupported(type: DeploymentType) {
  const allowlist: DeploymentType[] = [
    DeploymentType.DEPLOYMENTTYPE_OPTIMISM_MAINNET,
    DeploymentType.DEPLOYMENTTYPE_OPTIMISM_MAINNET_CELESTIA,
    DeploymentType.DEPLOYMENTTYPE_OPTIMISM_MAINNET_EIGENDA,
  ];

  return allowlist.includes(type);
}

/**
 * Function to parse the context from the State Machine into the correct type to pass to the API
 */
export function getDeploymentType({
  framework,
  environment,
  dataAvailability,
  settlement,
}: {
  framework: Framework;
  environment: RollupEnvironment;
  dataAvailability: DataAvailability;
  settlement: SettlementLayer;
}) {
  const entry = pipe(
    DeploymentMetadataMap,
    entries(),
    find(([, metadata]) => {
      if (
        framework === metadata.framework &&
        environment === metadata.environment &&
        dataAvailability === metadata.dataAvailability &&
        settlement === metadata.settlementLayer &&
        metadata.isConfigurable
      ) {
        return true;
      }

      return false;
    }),
  );

  if (!entry) {
    throw new Error(
      `Unhandled DeploymentType Mapping: ${JSON.stringify({
        framework,
        environment,
        dataAvailability,
        settlement,
      })}}`,
    );
  }

  return DeploymentType[entry[0]];
}

export function getIconByEnvironment(environment: RollupEnvironment) {
  switch (environment) {
    case ROLLUP_ENVIRONMENT.MAINNET:
      return GlobeAltIcon;
    case ROLLUP_ENVIRONMENT.TESTNET:
      return CommandLineIcon;
    // @ts-expect-error DEVNET not a valid production Environment
    case "DEVNET":
      return CodeBracketIcon;
  }
}

export function isTestnet(type: DeploymentType) {
  const environment = getEnvironment(type);

  if (!environment) {
    throw new Error(
      `Environment not configured for this deployment type, ${type}`,
    );
  }

  return environment === ROLLUP_ENVIRONMENT.TESTNET;
}

export function isMainnet(type: DeploymentType) {
  const environment = getEnvironment(type);

  if (!environment) {
    throw new Error(
      `Environment not configured for this deployment type, ${type}`,
    );
  }

  return environment === ROLLUP_ENVIRONMENT.MAINNET;
}

export function isArbitrum(type: DeploymentType) {
  const framework = getFramework(type);

  if (!framework) {
    throw new Error(
      `Framework not configured for this deployment type, ${type}`,
    );
  }

  return framework === FRAMEWORKS.ARBITRUM_ORBIT;
}

export function isOptimism(type: DeploymentType) {
  const framework = getFramework(type);

  if (!framework) {
    throw new Error(
      `Framework not configured for this deployment type, ${type}`,
    );
  }

  return framework === FRAMEWORKS.OPTIMISM;
}

export function isUsingCustomGasToken(network: Network) {
  return network.chains.some(
    (chain) => chain.nativeTokenAddress !== zeroAddress,
  );
}

export function isCelestia(type: DeploymentType) {
  const dataAvailability = getDataAvailability(type);

  if (!dataAvailability) {
    throw new Error(
      `Data Availability not configured for this deployment type, ${type}`,
    );
  }

  return dataAvailability === DATA_AVAILABILITY.CELESTIA;
}

export function isEigenDa(type: DeploymentType) {
  const dataAvailability = getDataAvailability(type);

  if (!dataAvailability) {
    throw new Error(
      `Data Availability not configured for this deployment type, ${type}`,
    );
  }

  return dataAvailability === DATA_AVAILABILITY.EIGEN;
}
