import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { Outlet } from "react-router-dom";

import { UserFacingError } from "../apis/userFacingError";
import { PylonSupportForm } from "../constants/urls";
import { Button } from "../shared/Button";
import { Panel } from "../shared/Panel";
import SystemLayout from "../shared/SystemLayout";

function parseErrorMessage(err: unknown): string {
  if (err instanceof UserFacingError) {
    return err?.props?.message || err?.message || "Something went wrong";
  }

  if (err instanceof Error) {
    return err.message;
  }

  return `${JSON.stringify(err)}`;
}

export default function ErrorBoundaryLayout() {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <SentryErrorBoundary
      fallback={({ error, resetError }) => (
        <SystemLayout>
          <Panel className="pb-5 text-center">
            <h1 className="mb-3 text-system-xl">
              A client side exception occurred
            </h1>

            <p className="mb-2 text-system-m text-grey-70">
              {parseErrorMessage(error)}
            </p>

            <Button
              className="mb-2 w-full"
              onClick={() => {
                try {
                  reset();
                } finally {
                  resetError();
                }
              }}
            >
              Try again
            </Button>

            <Button asChild className="mb-4 w-full" variant="secondary">
              <a href="/deployments">Back home</a>
            </Button>

            <p className="text-system-m text-grey-70">
              If this error persists, please{" "}
              <a
                target="_blank"
                rel="nofollow"
                href={PylonSupportForm}
                className="font-medium"
              >
                contact support
              </a>
              .
            </p>
          </Panel>
        </SystemLayout>
      )}
    >
      <Outlet />
    </SentryErrorBoundary>
  );
}
