import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { conduitAPI } from "../../apis/conduit-api";
import { useUserAuth } from "../../context/authContext";

export default function useCustomerPortal() {
  const { organization } = useUserAuth();

  const { id } = useParams<{ id: string | undefined }>();

  return useQuery({
    enabled: !!organization.organization,
    queryKey: ["CustomerPortal", organization.organization, id] as const,
    async queryFn({ queryKey: [, _organization, _network] }) {
      try {
        const resp = await conduitAPI.handleCustomerPortal({
          organization: _organization,
          network: _network,
        });

        return resp;
      } catch (err) {
        return {
          Url: undefined,
        };
      }
    },
    refetchOnWindowFocus: false,
    retry: false,
  });
}
