export type BillingDetail =
  | string
  | {
      price: number;
      period: "month";
    };

export type CostItem = {
  label: string;
  value: BillingDetail;
  tooltip?: string;
};

/**
 * Rollup Billing
 */

export const RollupPlans = {
  TESTNET: { price: 50, period: "month" },
  MAINNET: { price: 3000, period: "month" },
} as const;
