export { ArrowLeftRightIcon, BuiltByConduitIcon, Dot, PlusCircleIconAlt };

const PlusCircleIconAlt = ({
  className = "size-4",
}: {
  className?: string;
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8 6V10M10 8H6M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ArrowLeftRightIcon = ({ className = "" }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.53 5.53a.75.75 0 0 0-1.06-1.06l-3 3a.75.75 0 0 0 0 1.06l3 3a.75.75 0 0 0 1.06-1.06L3.81 8.75H12.19l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H3.81l1.72-1.72Z"
      clipRule="evenodd"
    />
  </svg>
);

const Dot = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="12.1" cy="12.1" r="1" />
  </svg>
);

const BuiltByConduitIcon = ({ size = 16 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
  >
    <circle cx={8} cy={8} r={8} fill="#26B6B7" />
    <path
      fill="#fff"
      d="M9.37 5.593 8 8h2.67a1.662 1.662 0 0 0-1.46 1.667c0 .259.05.506.15.716L7.998 8l-1.36 2.383c.099-.21.148-.457.148-.716 0-.877-.63-1.556-1.458-1.667H8L6.627 5.593a1.638 1.638 0 0 0 2.743 0Zm.272 5.74c0-.259-.061-.518-.173-.753a1.61 1.61 0 0 0 1.385.753c.926 0 1.643-.74 1.643-1.666 0-.926-.704-1.655-1.619-1.667.915-.012 1.62-.74 1.62-1.667 0-.926-.718-1.666-1.644-1.666-.594 0-1.1.296-1.397.765a1.56 1.56 0 0 0 .185-.765C9.642 3.74 8.926 3 8 3s-1.644.74-1.644 1.667c0 .284.062.543.185.777a1.61 1.61 0 0 0-1.396-.777c-.927 0-1.644.74-1.644 1.666 0 .926.704 1.655 1.619 1.667-.915.012-1.619.74-1.619 1.667 0 .926.717 1.666 1.644 1.666a1.61 1.61 0 0 0 1.384-.753 1.769 1.769 0 0 0-.173.753c0 .926.717 1.667 1.644 1.667.927 0 1.643-.74 1.643-1.667Z"
    />
  </svg>
);
