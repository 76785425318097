import { Outlet } from "react-router-dom";
import { isEmpty, isNonNull } from "remeda";

import { useUserAuth } from "../../context/authContext";
import AppFooter from "../../shared/AppFooter";
import Spinner from "../../shared/Spinner";
import SystemLayout from "../../shared/SystemLayout";
import DiscordGuidanceButton from "./components/DiscordGuidanceButton";

export default function DeployLayout() {
  const { user } = useUserAuth();

  /**
   * @note we wanna allow logged out users to see this page as well, so we check if its not null, bc null means its a logged out user, isEmpty means its waiting for the user state to load
   */
  if (isNonNull(user) && isEmpty(user as Record<string, unknown>)) {
    return (
      <SystemLayout>
        <Spinner className="size-6 text-accent" />
      </SystemLayout>
    );
  }

  return (
    <>
      <Outlet />

      <DiscordGuidanceButton />

      <AppFooter />
    </>
  );
}
