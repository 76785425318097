import { RollupPlans } from "./rollupBilling";

export const SharedLineItems = {
  ENTERPRISE_CONTRACT_MAINNET: [
    "Built-in block explorer, tracer, and bridge",
    "24/7 security monitoring and best-in-class architecture",
    "High-availability sequencing with Conduit Elector",
    "Hypernode RPC that scales with your usage",
    "Automated sequencer fee disbursement and accounting",
    "Dedicated customer support and custom SLAs",
    "Feature priority",
    "Advanced customization",
  ],
  SELF_SERVE_MAINNET: [
    "Built-in block explorer, tracer, and bridge",
    "Best-in-class security monitoring and architecture",
    "High-availability sequencing with Conduit Elector",
    "Hypernode RPC that scales with your usage",
    "Priority support",
    "Automated sequencer fee disbursement and accounting",
  ],
  SELF_SERVE_TESTNET: [
    "Built-in block explorer, tracer, and bridge",
    "Best-in-class security monitoring and architecture",
    "High-availability sequencing with Conduit Elector",
    "Hypernode RPC that scales with your usage",
    "Discord support",
  ],
  DEVNET: ["Built-in block explorer, tracer, faucet, and bridge"],
} as const;

export const PlanCostCenters = {
  TESTNET: {
    label: "Plan cost",
    value: RollupPlans.TESTNET,
  },
  MAINNET: {
    label: "Plan cost",
    value: RollupPlans.MAINNET,
  },
  ENTERPRISE: {
    label: "Plan cost",
    value: "Enterprise contract",
  },
} as const;

export const SequencerProfitShareCenters = {
  L3_STANDARD: {
    label: "Sequencer profit share",
    value: "7.5%",
    tooltip:
      "Rollups earn sequencer revenue in ETH. When in profit, the rollup keeps 92.5% and Conduit receives 7.5%.",
  },
  ARBITRUM_L2: {
    label: "Sequencer profit share",
    value: "17.5%",
    tooltip:
      "Rollups earn sequencer revenue in ETH. When in profit, the rollup keeps 82.5%, Arbitrum DAO receives 10% and Conduit receives 7.5%.",
  },
  CUSTOM: {
    label: "Sequencer profit share",
    value: "Custom",
    tooltip:
      "Rollups earn sequencer revenue in ETH. When in profit, the rollup keeps the majority share while Conduit and Optimism each receive a percentage.",
  },
} as const;

export const DeploymentCostCenters = {
  TESTNET: {
    label: "Deployment cost",
    value: "Free",
    tooltip: "One-time ETH gas fee required to deploy your rollup.",
  },
} as const;

export const SharedCostItems = {
  SELF_SERVE_TESTNET: [DeploymentCostCenters.TESTNET, PlanCostCenters.TESTNET],
  SELF_SERVE_ARBITRUM_L2: [
    SequencerProfitShareCenters.ARBITRUM_L2,
    PlanCostCenters.MAINNET,
  ],
  SELF_SERVE_MAINNET_L3: [
    SequencerProfitShareCenters.L3_STANDARD,
    PlanCostCenters.MAINNET,
  ],
  ENTERPRISE_MAINNET_ARBITRUM_L2: [
    SequencerProfitShareCenters.ARBITRUM_L2,
    PlanCostCenters.ENTERPRISE,
  ],
  ENTERPRISE_MAINNET_OPTIMISM: [
    SequencerProfitShareCenters.CUSTOM,
    PlanCostCenters.ENTERPRISE,
  ],
} as const;
