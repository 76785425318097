import { CheckIcon } from "@heroicons/react/20/solid";
import { forwardRef } from "react";
import { NavLink, type NavLinkProps } from "react-router-dom";

import type { Network } from "../../apis/api-types";
import NetworkAvatar from "../NetworkAvatar";

interface RollupItemProps extends NavLinkProps {
  network: Network;
  isActiveNetwork: boolean;
}

export const RollupItem = forwardRef<HTMLAnchorElement, RollupItemProps>(
  ({ network, to, isActiveNetwork, ...rest }, ref) => {
    return (
      <NavLink
        {...rest}
        to={to}
        ref={ref}
        className="flex w-full items-center gap-2.5 rounded p-2 text-left transition-colors hover:bg-grey-10 focus:bg-grey-10"
      >
        {({ isActive }) => (
          <>
            <NetworkAvatar
              network={network.network}
              logoUrl={network.logoUrl}
              className="size-6"
            />

            <p className="flex-1 truncate text-system-m text-black">
              {network.name ?? "..."}
            </p>

            {isActiveNetwork || isActive ? (
              <CheckIcon className="size-4 text-accent" />
            ) : null}
          </>
        )}
      </NavLink>
    );
  },
);

RollupItem.displayName = "RollupItem";

export const RollupItemSkeleton = () => {
  return (
    <div className="flex w-full items-center gap-2.5 p-2">
      <div className="h-6 w-6 shrink-0 animate-pulse rounded-full bg-grey-30" />
      <div className="my-1 h-3.5 w-3/4 animate-pulse rounded bg-grey-30"></div>
    </div>
  );
};
