import { Query, queryOptions, useQuery } from "@tanstack/react-query";
import type { GetNetworkRequest, Network } from "../../apis/api-types";
import { conduitAPI } from "../../apis/conduit-api";
import { useUserAuth } from "../../context/authContext";
import { isCelestia, isEigenDa } from "../../utils/metadata";

export function useGetNetwork(
  slugOrId: string | undefined,
  /**
   * @todo make this better
   */
  options: {
    retry?: boolean;
    refetchInterval?:
      | number
      | false
      | ((
          query: Query<
            Network,
            Error,
            Network,
            readonly ["GetNetwork", string, string | undefined]
          >,
        ) => number | false | undefined);
  } = {},
) {
  const { organization } = useUserAuth();

  return useQuery({
    enabled: !!organization && !!slugOrId,
    ...options,
    queryKey: ["GetNetwork", organization.organization, slugOrId] as const,
    queryFn: () =>
      patchGetNetwork({
        organization: organization.organization,
        network: slugOrId!,
      }),
  });
}

export function getNetwork({
  organization,
  network,
}: {
  organization: string;
  network: string;
}) {
  return queryOptions({
    queryKey: ["GetNetwork", organization, network] as const,
    queryFn: () => patchGetNetwork({ organization, network }),
    refetchOnWindowFocus: false,
  });
}

async function patchGetNetwork(args: GetNetworkRequest) {
  const resp = await conduitAPI.getNetwork(args);

  const information = resp.network.information.filter(
    (el) => !["Settlement Layer", "Data Availability Layer"].includes(el.name),
  );

  information.splice(
    2,
    0,
    {
      name: "Framework",
      value: resp.network.type,
      url: "",
      network: resp.network.network,
    },
    {
      name: "Settlement layer",
      value: resp.network.type,
      url: "",
      network: resp.network.network,
    },
    {
      name: "Data availability layer",
      value: resp.network.type,
      url: "",
      network: resp.network.network,
    },
    {
      name: "Environment",
      value: resp.network.type,
      url: "",
      network: resp.network.network,
    },
  );

  const urls = resp.network.urls.map((el) => {
    if (
      el.displayName === "AnyTrust" &&
      (isCelestia(resp.network.type) || isEigenDa(resp.network.type))
    ) {
      return { ...el, displayName: "AnyTrust (Fallback)" };
    }

    return el;
  });

  return {
    ...resp.network,
    information,
    urls,
  } as Network;
}
