import { useQuery } from "@tanstack/react-query";
import { conduitAPI } from "../../apis/conduit-api";
import { useUserAuth } from "../../context/authContext";

export default function useListTestnets() {
  const { organization } = useUserAuth();

  return useQuery({
    enabled: !!organization?.organization,
    queryKey: ["DeploymentsList", organization.organization] as const,
    async queryFn({ queryKey: [, _organization] }) {
      const resp = await conduitAPI.listNetworks({
        organization: _organization,
      });

      if (resp.error) {
        throw new Error(resp.error.message);
      }

      return resp.networks;
    },
  });
}
