import { cva } from "class-variance-authority";

export const button = cva(
  "inline-flex shrink-0 items-center justify-center whitespace-nowrap rounded-lg border-0.5 font-medium transition focus:outline-none disabled:cursor-not-allowed data-[disabled]:cursor-not-allowed",
  {
    variants: {
      size: {
        medium: "h-9 px-[calc(0.75rem-0.5px)] text-system-m",
        small: "h-7 px-[calc(0.625rem-0.5px)] text-system-s leading-[19px]",
      },
      variant: {
        primary:
          "border-blue-70/90 bg-blue-60 text-white shadow-button-primary hover:bg-blue-70",
        secondary:
          "border-grey-40 bg-grey-0 text-black shadow-button-secondary hover:bg-grey-10 data-[disabled]:border-grey-30 data-[disabled]:bg-grey-10 data-[disabled]:text-grey-50 data-[disabled]:shadow-none data-[disabled]:hover:bg-grey-10",
        destructive:
          "border-red-80/90 bg-red-50 text-white shadow-button-primary hover:bg-red-60 data-[disabled]:border-grey-30 data-[disabled]:bg-grey-10 data-[disabled]:text-grey-50 data-[disabled]:shadow-none data-[disabled]:hover:bg-grey-10",
        pending: "border-grey-30 bg-grey-20 text-grey-60",
        ghost: "border-grey-40 bg-white text-black",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "medium",
    },
  },
);
