import type { User as FirebaseUser, User } from "firebase/auth";
import { isEmpty } from "remeda";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { useShallow } from "zustand/react/shallow";

import type { GetUserResponse, Organization } from "../apis/api-types";
import { CURRENT_ORGANIZATION } from "../constants/localStorage";
import { isUser } from "../utils/assertUser";

type EmptyObject = Record<PropertyKey, never>;

type AuthStore = {
  email: string;
  name: string;
  organization: Organization | EmptyObject;
  organizations: Organization[];
  user: FirebaseUser | EmptyObject | null;
  userObject: GetUserResponse | EmptyObject;
};

const initialState: AuthStore = {
  email: "",
  name: "",
  organization: {},
  organizations: [],
  user: {},
  userObject: {},
};

export const useAuthStore = create<AuthStore>()(
  devtools(() => ({ ...initialState })),
);

export const setUser = (value: User | EmptyObject | null) => {
  useAuthStore.setState({ user: value });
};

export const setUserObject = (value: GetUserResponse | EmptyObject) => {
  useAuthStore.setState({ userObject: value });
};

export const setName = (value: string) => {
  useAuthStore.setState({ name: value });
};

export const setEmail = (value: string) => {
  useAuthStore.setState({ email: value });
};

export const clearUserData = () => {
  try {
    window.localStorage.removeItem(CURRENT_ORGANIZATION);
  } catch (error) {
    console.error(error);
  }

  useAuthStore.setState(initialState);
};

export const addNewOrganization = (value: Organization) => {
  useAuthStore.setState((state) => {
    if (
      isEmpty(
        state.organizations.filter(
          (el) => el.organization === value.organization,
        ),
      )
    ) {
      return { organizations: [...state.organizations, value] };
    }

    return {};
  });
};

export const setCurrentOrganization = (value: Organization) => {
  try {
    window.localStorage.setItem(CURRENT_ORGANIZATION, value.organization);
  } catch (error) {
    console.error(error);
  }

  useAuthStore.setState({ organization: value });
};

export const setOrganizations = (value: Organization[]) => {
  useAuthStore.setState({ organizations: value });
};

/**
 * Composed Hooks
 */

export function useUserIsLoggedIn() {
  return useAuthStore(
    useShallow(
      (state) =>
        isUser(state.user) &&
        !isEmpty(state.userObject as Record<string, unknown>),
    ),
  );
}

export function useUserUUID() {
  return useAuthStore(useShallow((state) => state.userObject.user.user));
}
