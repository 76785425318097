import { Outlet, useParams } from "react-router-dom";

import { assertUserFacingError } from "../../../apis/userFacingError";
import { useGetNetwork } from "../../../hooks/api/useGetTestnet";
import { NotFoundPanel } from "../../../shared/NotFoundPanels";

export default function ViewNetworkLayout() {
  const { id } = useParams();

  const { error } = useGetNetwork(id, { retry: false });

  if (error) {
    assertUserFacingError(error);

    if (
      error?.props?.code === 404 ||
      error?.props?.code === 400 ||
      error?.message.includes("network not found")
    ) {
      return (
        <div className="flex flex-1 flex-col items-center justify-center border-t-0.5 border-grey-40">
          <NotFoundPanel />
        </div>
      );
    }

    throw error;
  }

  return <Outlet />;
}
