import clsx from "clsx";
import { m } from "framer-motion";
import { useMemo } from "react";
import { Link, NavLink, type NavLinkProps, useParams } from "react-router-dom";

import { PrestartJob_Status, PrestartJob_Type } from "../apis/api-types";
import { IntegrationsURL } from "../constants/urls";
import { useGetNetwork } from "../hooks/api/useGetTestnet";
import { pb } from "../lib/pb";
import ConduitLogomark from "../shared/ConduitLogo";
import { cn } from "../utils/twx";
import { UserDropdownManager } from "./UserDropDown";

interface AppNavLinkTabProps extends NavLinkProps {
  layoutPrefix?: string;
  disabled?: boolean;
}

export const AppNavLinkTab = ({
  className,
  children,
  layoutPrefix = "top",
  disabled,
  ...rest
}: AppNavLinkTabProps) => {
  return (
    <NavLink
      className={({ isPending, isActive }) =>
        clsx(
          "navLink",
          { active: isActive, pending: isPending, disabled: disabled },
          className,
        )
      }
      {...rest}
    >
      {({ isActive }) => (
        <>
          {children}

          {isActive ? (
            <m.div
              layoutId={`${layoutPrefix}-appNavLinkTab`}
              className="absolute inset-x-0 bottom-0 h-0.5 w-full rounded-t-full bg-black"
            />
          ) : undefined}
        </>
      )}
    </NavLink>
  );
};

export function AppTopLevelNav({
  className = "",
  slot,
}: {
  className?: string;
  slot?: React.ReactNode;
}) {
  return (
    <div className={cn("shrink-0 px-6 pb-5 pt-5", className)}>
      <nav className="flex items-center gap-6" role="navigation">
        <Link
          to={pb.deployments()}
          className="size-8 transition-opacity hover:opacity-80 focus:opacity-80 focus:outline-none"
        >
          <span className="sr-only">Back to Home</span>
          <ConduitLogomark />
        </Link>

        {slot}

        <div className="ml-auto">
          <UserDropdownManager />
        </div>
      </nav>
    </div>
  );
}

export function AppNav() {
  const { id } = useParams<{ id: string | undefined }>();

  const { data } = useGetNetwork(id, {
    retry: false,
  });

  const networkIsDeploying = useMemo(() => {
    if (data) {
      return data.prestartJobs.some(
        (job) =>
          job.type === PrestartJob_Type.STANDARD &&
          job.status !== PrestartJob_Status.COMPLETED,
      );
    }

    return false;
  }, [data]);

  return (
    <div className="shrink-0 overflow-scroll border-b-0.5 border-grey-40 bg-white px-6 pt-3 sm:overflow-hidden">
      <nav className="flex gap-5" role="navigation">
        {id ? (
          <>
            <AppNavLinkTab
              layoutPrefix="dashboardNav"
              test-id="NetworkOverview"
              to={pb.dashboardOverview({ slugOrId: id })}
              onClick={(e) =>
                networkIsDeploying ? e.preventDefault() : undefined
              }
              disabled={networkIsDeploying}
            >
              <span>Overview</span>
            </AppNavLinkTab>

            <AppNavLinkTab
              layoutPrefix="dashboardNav"
              test-id="NetworkMetrics"
              to={pb.dashboardMetrics({ slugOrId: id })}
              onClick={(e) =>
                networkIsDeploying ? e.preventDefault() : undefined
              }
              disabled={networkIsDeploying}
            >
              <span>Metrics</span>
            </AppNavLinkTab>

            <AppNavLinkTab
              layoutPrefix="dashboardNav"
              test-id="NetworkContracts"
              to={pb.dashboardContracts({ slugOrId: id })}
              onClick={(e) =>
                networkIsDeploying ? e.preventDefault() : undefined
              }
              disabled={networkIsDeploying}
            >
              <span>Contracts</span>
            </AppNavLinkTab>

            <AppNavLinkTab
              layoutPrefix="dashboardNav"
              test-id="NetworkIntegrations"
              to={pb.dashboardMarketplace({ slugOrId: id })}
              onClick={(e) =>
                networkIsDeploying ? e.preventDefault() : undefined
              }
              disabled={networkIsDeploying}
            >
              <span>Apps</span>
            </AppNavLinkTab>

            <AppNavLinkTab
              layoutPrefix="dashboardNav"
              test-id="NetworkSettings"
              to={pb.dashboardSettings({ slugOrId: id })}
              onClick={(e) =>
                networkIsDeploying ? e.preventDefault() : undefined
              }
              disabled={networkIsDeploying}
            >
              <span>Settings</span>
            </AppNavLinkTab>
          </>
        ) : (
          <>
            <AppNavLinkTab layoutPrefix="appNav" to={pb.deployments()}>
              <span>Your deployments</span>
            </AppNavLinkTab>

            <AppNavLinkTab layoutPrefix="appNav" to={pb.nodes()}>
              <span>Nodes</span>
            </AppNavLinkTab>

            <AppNavLinkTab layoutPrefix="appNav" to={pb.browseNetworks()}>
              <span>Browse rollups</span>
            </AppNavLinkTab>

            <AppNavLinkTab
              layoutPrefix="appNav"
              to={IntegrationsURL}
              target="_blank"
            >
              <span>Integrations</span>
            </AppNavLinkTab>

            <AppNavLinkTab layoutPrefix="appNav" to={pb.organizationSettings()}>
              <span>Settings</span>
            </AppNavLinkTab>
          </>
        )}
      </nav>
    </div>
  );
}
