/* eslint-disable react-refresh/only-export-components */

import type { VariantProps } from "class-variance-authority";
import { twc, type TwcComponentProps } from "react-twc";

import { button } from "./button.styles";

interface ButtonProps
  extends TwcComponentProps<"button">,
    VariantProps<typeof button> {}

export const Button = twc.button.transientProps([
  "variant",
  "size",
])<ButtonProps>(({ variant, size }) => button({ variant, size }));

export const ButtonPrimary_test = () => (
  <Button variant="primary">Button</Button>
);

export const ButtonSecondary_test = () => (
  <Button variant="secondary">Button</Button>
);

export const ButtonSecondaryDisabled_test = () => (
  <Button variant="secondary" disabled data-disabled>
    Button
  </Button>
);

export const ButtonDestructive_test = () => (
  <Button variant="destructive">Button</Button>
);

export const ButtonPending_test = () => (
  <Button variant="pending">Button</Button>
);
