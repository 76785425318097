export const DeploymentType = {
  DEPLOYMENTTYPE_ANVIL: "DEPLOYMENTTYPE_ANVIL",
  DEPLOYMENTTYPE_GETH: "DEPLOYMENTTYPE_GETH",
  DEPLOYMENTTYPE_ERIGON: "DEPLOYMENTTYPE_ERIGON",
  DEPLOYMENTTYPE_OPTIMISM: "DEPLOYMENTTYPE_OPTIMISM",
  DEPLOYMENTTYPE_CUSTOM: "DEPLOYMENTTYPE_CUSTOM",
  DEPLOYMENTTYPE_OPTIMISM_DEVNET: "DEPLOYMENTTYPE_OPTIMISM_DEVNET",
  DEPLOYMENTTYPE_OPTIMISM_GOERLI: "DEPLOYMENTTYPE_OPTIMISM_GOERLI",
  DEPLOYMENTTYPE_OPTIMISM_MAINNET: "DEPLOYMENTTYPE_OPTIMISM_MAINNET",
  DEPLOYMENTTYPE_OPTIMISM_GOERLI_L3: "DEPLOYMENTTYPE_OPTIMISM_GOERLI_L3",
  DEPLOYMENTTYPE_OPTIMISM_MAINNET_L3: "DEPLOYMENTTYPE_OPTIMISM_MAINNET_L3",
  DEPLOYMENTTYPE_OPTIMISM_CHAINMAKER_TEST: "DEPLOYMENTTYPE_OPTIMISM_CHAINMAKER_TEST",
  DEPLOYMENTTYPE_OPTIMISM_SEPOLIA: "DEPLOYMENTTYPE_OPTIMISM_SEPOLIA",
  DEPLOYMENTTYPE_OPTIMISM_SEPOLIA_CELESTIA: "DEPLOYMENTTYPE_OPTIMISM_SEPOLIA_CELESTIA",
  DEPLOYMENTTYPE_OPTIMISM_SEPOLIA_EIGENDA: "DEPLOYMENTTYPE_OPTIMISM_SEPOLIA_EIGENDA",
  DEPLOYMENTTYPE_OPTIMISM_MAINNET_EIGENDA: "DEPLOYMENTTYPE_OPTIMISM_MAINNET_EIGENDA",
  DEPLOYMENTTYPE_OPTIMISM_MAINNET_CELESTIA: "DEPLOYMENTTYPE_OPTIMISM_MAINNET_CELESTIA",
  DEPLOYMENTTYPE_OPTIMISM_BASE_SEPOLIA: "DEPLOYMENTTYPE_OPTIMISM_BASE_SEPOLIA",
  DEPLOYMENTTYPE_OPTIMISM_BASE_SEPOLIA_CELESTIA: "DEPLOYMENTTYPE_OPTIMISM_BASE_SEPOLIA_CELESTIA",
  DEPLOYMENTTYPE_OPTIMISM_BASE_MAINNET: "DEPLOYMENTTYPE_OPTIMISM_BASE_MAINNET",
  DEPLOYMENTTYPE_OPTIMISM_BASE_MAINNET_CELESTIA: "DEPLOYMENTTYPE_OPTIMISM_BASE_MAINNET_CELESTIA",
  DEPLOYMENTTYPE_OPTIMISM_BASE_MAINNET_EIGENDA: "DEPLOYMENTTYPE_OPTIMISM_BASE_MAINNET_EIGENDA",
  DEPLOYMENTTYPE_OPTIMISM_ZORA_SEPOLIA: "DEPLOYMENTTYPE_OPTIMISM_ZORA_SEPOLIA",
  DEPLOYMENTTYPE_OPTIMISM_ZORA_SEPOLIA_CELESTIA: "DEPLOYMENTTYPE_OPTIMISM_ZORA_SEPOLIA_CELESTIA",
  DEPLOYMENTTYPE_OPTIMISM_ZORA_MAINNET: "DEPLOYMENTTYPE_OPTIMISM_ZORA_MAINNET",
  DEPLOYMENTTYPE_OPTIMISM_ZORA_MAINNET_CELESTIA: "DEPLOYMENTTYPE_OPTIMISM_ZORA_MAINNET_CELESTIA",
  DEPLOYMENTTYPE_OPTIMISM_MODE_SEPOLIA: "DEPLOYMENTTYPE_OPTIMISM_MODE_SEPOLIA",
  DEPLOYMENTTYPE_OPTIMISM_MODE_SEPOLIA_CELESTIA: "DEPLOYMENTTYPE_OPTIMISM_MODE_SEPOLIA_CELESTIA",
  DEPLOYMENTTYPE_OPTIMISM_MODE_MAINNET: "DEPLOYMENTTYPE_OPTIMISM_MODE_MAINNET",
  DEPLOYMENTTYPE_OPTIMISM_MODE_MAINNET_CELESTIA: "DEPLOYMENTTYPE_OPTIMISM_MODE_MAINNET_CELESTIA",
  DEPLOYMENTTYPE_OPTIMISM_BASE_SEPOLIA_EIGENDA: "DEPLOYMENTTYPE_OPTIMISM_BASE_SEPOLIA_EIGENDA",
  DEPLOYMENTTYPE_OPTIMISM_SEPOLIA_PLASMA: "DEPLOYMENTTYPE_OPTIMISM_SEPOLIA_PLASMA",
  DEPLOYMENTTYPE_OPTIMISM_MAINNET_PLASMA: "DEPLOYMENTTYPE_OPTIMISM_MAINNET_PLASMA",
  DEPLOYMENTTYPE_OPTIMISM_BASE_SEPOLIA_PLASMA: "DEPLOYMENTTYPE_OPTIMISM_BASE_SEPOLIA_PLASMA",
  DEPLOYMENTTYPE_OPTIMISM_BASE_MAINNET_PLASMA: "DEPLOYMENTTYPE_OPTIMISM_BASE_MAINNET_PLASMA",
  DEPLOYMENTTYPE_STARKWARE_MADARA_TESTNET: "DEPLOYMENTTYPE_STARKWARE_MADARA_TESTNET",
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA: "DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA",
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_L2: "DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_L2",
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_ARBITRUM_MAINNET: "DEPLOYMENTTYPE_ARBITRUM_ORBIT_ARBITRUM_MAINNET",
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_MAINNET: "DEPLOYMENTTYPE_ARBITRUM_ORBIT_MAINNET",
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_ANYTRUST: "DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_ANYTRUST",
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_L2_ANYTRUST: "DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_L2_ANYTRUST",
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_ARBITRUM_MAINNET_ANYTRUST: "DEPLOYMENTTYPE_ARBITRUM_ORBIT_ARBITRUM_MAINNET_ANYTRUST",
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_MAINNET_ANYTRUST: "DEPLOYMENTTYPE_ARBITRUM_ORBIT_MAINNET_ANYTRUST",
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_CELESTIA: "DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_CELESTIA",
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_L2_CELESTIA: "DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_L2_CELESTIA",
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_BASE_SEPOLIA_ANYTRUST: "DEPLOYMENTTYPE_ARBITRUM_ORBIT_BASE_SEPOLIA_ANYTRUST",
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_BASE_MAINNET_ANYTRUST: "DEPLOYMENTTYPE_ARBITRUM_ORBIT_BASE_MAINNET_ANYTRUST",
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_EIGENDA: "DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_EIGENDA",
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_L2_EIGENDA: "DEPLOYMENTTYPE_ARBITRUM_ORBIT_SEPOLIA_L2_EIGENDA",
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_MAINNET_EIGENDA: "DEPLOYMENTTYPE_ARBITRUM_ORBIT_MAINNET_EIGENDA",
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_MAINNET_L2_EIGENDA: "DEPLOYMENTTYPE_ARBITRUM_ORBIT_MAINNET_L2_EIGENDA",
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_MAINNET_L2_CELESTIA: "DEPLOYMENTTYPE_ARBITRUM_ORBIT_MAINNET_L2_CELESTIA",
  DEPLOYMENTTYPE_ARBITRUM_ORBIT_MAINNET_CELESTIA: "DEPLOYMENTTYPE_ARBITRUM_ORBIT_MAINNET_CELESTIA",
  DEPLOYMENTTYPE_OPTIMISM_MODE_SEPOLIA_EIGENDA: "DEPLOYMENTTYPE_OPTIMISM_MODE_SEPOLIA_EIGENDA",
  DEPLOYMENTTYPE_OPTIMISM_MODE_MAINNET_EIGENDA: "DEPLOYMENTTYPE_OPTIMISM_MODE_MAINNET_EIGENDA",
} as const;

export type DeploymentType = keyof typeof DeploymentType;
