import retry from "async-retry";
import { onIdTokenChanged, type User } from "firebase/auth";
import { assertError } from "ts-extras";

import { ApiHostUrl } from "../constants/urls";
import { auth } from "../lib/firebase";
import { UserFacingError } from "./userFacingError";

export class FirebaseTokenManager {
  /**
   * Firebase Bearer JWT to pass to the Conduit API
   */
  protected idToken: string | null = null;

  /**
   * Firebase User
   */
  protected user: User | null = null;

  constructor() {
    onIdTokenChanged(auth, (user) => {
      void this.setIdToken(user);
    });
  }

  protected async setIdToken(user: User | null) {
    console.info("[FirebaseTokenManager]", "setIdToken", user);

    this.user = user;

    if (user) {
      /**
       * @note Undocumented 'accessToken' on Firebase User
       */
      if ("accessToken" in user) {
        this.idToken = user.accessToken as string;
      } else {
        this.idToken = await user.getIdToken();
      }
    }
  }

  protected async authorize() {
    if (!this.user) {
      return;
    }

    try {
      const token = await this.user.getIdToken(true);

      console.info("[FirebaseTokenManager]", "authorize", {
        token,
      });

      this.idToken = token;
    } catch (err) {
      console.error("[FirebaseTokenManager]", "authorize", err);
    }
  }
}

export class APIUtilities extends FirebaseTokenManager {
  /**
   * The hostname of the API
   */
  protected apiHost = ApiHostUrl;

  constructor() {
    super();
  }

  protected async fetcher<T>({
    input,
    init,
    readBodyAsText = false,
    readBodyAsStream = false,
    retries = 5,
    shouldRetryOnFetchError = true,
  }: {
    input: URL | RequestInfo;
    init?: RequestInit | undefined;
    readBodyAsText?: boolean;
    readBodyAsStream?: boolean;
    retries?: number;
    shouldRetryOnFetchError?: boolean;
  }): Promise<T> {
    return retry<T>(
      async (bail) => {
        let res: Response;

        try {
          res = await fetch(input, {
            ...init,
            cache: "no-cache",
            credentials: "include",
            redirect: "error",
            headers: {
              ...init?.headers,
              Authorization: `Bearer ${this.idToken}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });
        } catch (err) {
          assertError(err);

          console.error(err);

          if (shouldRetryOnFetchError) {
            throw err;
          }

          return bail(err);
        }

        if (res.ok) {
          if (readBodyAsText) {
            try {
              return res.text();
            } catch (err) {
              console.error(err);

              return bail(new Error("Failed to read Response as text"));
            }
          }

          if (readBodyAsStream) {
            try {
              return res.body;
            } catch (err) {
              console.error(err);

              return bail(new Error("Failed to read Response as stream"));
            }
          }

          try {
            return res.json();
          } catch (err) {
            console.error(err);

            return bail(new Error("Failed to read Response as JSON"));
          }
        }

        /**
         * Always retry 401 status
         */
        if (401 === res.status) {
          await this.authorize();

          throw new Error("Unauthorized");
        }

        const text = await res.text();

        let jsonText: { message: string } | undefined;

        try {
          jsonText = (await JSON.parse(text)) as { message: string };
        } catch (err) {
          /**
           * Failed to parse JSON
           */
        }

        const message = jsonText ? jsonText.message : text;

        const err = new UserFacingError(
          `Code: ${res.status} Reason: ${message}`,
          { code: res.status, message: message },
        );

        /**
         * These error codes should not be retried
         */
        if (res.status >= 400 && res.status < 500) {
          return bail(err);
        }

        throw err;
      },
      {
        retries,
        onRetry(_, attempt) {
          console.info("[Conduit API]", "retry attempt", attempt);
        },
      },
    );
  }

  protected get<T>(endpoint: `/v1/${string}` | `/public/${string}`) {
    return this.fetcher<T>({
      input: new URL(endpoint, this.apiHost),
      init: { method: "GET" },
      retries: 0,
    });
  }

  protected getWithRetry<T>(
    endpoint: `/v1/${string}` | `/public/${string}`,
    opts: { shouldRetryOnFetchError?: boolean; retries?: number } = {},
  ) {
    return this.fetcher<T>({
      input: new URL(endpoint, this.apiHost),
      init: { method: "GET" },
      ...opts,
    });
  }

  protected post<T>(
    endpoint: `/v1/${string}` | `/public/${string}`,
    body: unknown,
    signal?: AbortSignal,
  ) {
    return this.fetcher<T>({
      input: new URL(endpoint, this.apiHost),
      init: { method: "POST", body: JSON.stringify(body), signal },
      retries: 0,
    });
  }

  protected postResAsText<T>(
    endpoint: `/v1/${string}` | `/public/${string}`,
    body: unknown,
  ) {
    return this.fetcher<T>({
      input: new URL(endpoint, this.apiHost),
      init: { method: "POST", body: JSON.stringify(body) },
      retries: 0,
      readBodyAsText: true,
    });
  }

  protected postWithRetry<T>(
    endpoint: `/v1/${string}` | `/public/${string}`,
    body: unknown,
    signal?: AbortSignal,
  ) {
    return this.fetcher<T>({
      input: new URL(endpoint, this.apiHost),
      init: { method: "POST", body: JSON.stringify(body), signal },
    });
  }
}
