import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { LazyMotion } from "framer-motion";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { Toaster } from "sonner";

import { AuthContextProvider } from "../context/authContext";
import { queryClient } from "../lib/query/client";
import { VercelAnalytics } from "../lib/vercel";

export default function RootLayout() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <LazyMotion
          features={() =>
            import("../lib/framer-motion/features.js").then(
              (res) => res.default,
            )
          }
          strict
        >
          <Outlet />
        </LazyMotion>

        <Toaster />

        <ScrollRestoration />

        <VercelAnalytics />
      </AuthContextProvider>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
